import React, { Fragment, useCallback, useEffect, useState } from 'react';
import mova from 'mova';
import PropTypes from 'prop-types';
import './FinishReservation.scss';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import Timer from '@components/feedback/Timer/Timer';
import Image from '@components/display/Image/Image';
import Colors from '@data/enums/Color.enum';
import SocialLoginButton from '@components/inputs/SocialLoginButton/SocialLoginButton';
import TextInput from '@components/inputs/TextInput/TextInput';
import PhoneInput from '@components/inputs/PhoneInput/PhoneInput';
import TextArea from '@components/inputs/TextArea/TextArea';
import ConfirmLegal from '@components/display/ConfirmLegal/ConfirmLegal';
import { DEFAULT_LANG, PROVIDER_FACEBOOK } from '@constants/app';
import { isDefined } from '@utils/lo/lo';
import Form from '@components/inputs/Form/Form';
import Loading from '@components/feedback/Loading/Loading';
import PlaceContacts from '@components/display/PlaceContacts/PlaceContacts';
import ReservationDetails from '@components/display/ReservationDetails/ReservationDetails';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';
import { phoneRegexp } from '@utils/validation/validationUtils';
import Entity404 from '../Error/Entity404';
import ReservationNotDraft from '../Error/ReservationNotDraft';
import { STATUS_DRAFT } from '@constants/reservation';
import TextLink from '@components/navigation/TextLink/TextLink';
import { baseUrlPath } from '@utils/url/urlUtils';
import InfoTagsFormsy from '@components/display/InfoTags/InfoTagsFormsy';
import InfoTag from '@components/display/InfoTags/InfoTag';
import SelectQuantity from '../../components/display/SelectQuantity/SelectQuantity';
import moment from 'moment';
import Button from '../../components/inputs/Button/Button';
import { toastr } from 'react-redux-toastr';
import { getCertificateIcon } from "../../../utils/certificate";

const t = mova.ns('pages.FinishReservation');
const errorsT = mova.ns('errors.validation');

const getDepositName = (deposits, id) => deposits.find(d => d.id === id)?.label;

const getDepositTypeName = (deposits, id) => deposits.flatMap(d => d.types).find(t => t.id === id)?.label;

const getDepositTypePrice = (reservation, deposit, type) => type[moment(reservation.from).isoWeekday()] * deposit.count;

const getTotalDepositPrice = (reservation, deposits) => {
  return deposits.reduce((acc, deposit) => acc + getDepositTypePrice(reservation, deposit, deposit.type), 0);
};

function FinishReservation(
  {
    match, getReservation, getPlace, getDeposits, deposits, confirmReservation, user, loading,
    reservation, place, history, saveRegistrationData, openAskPhoneModal, closeModal, findCertificate
  }
) {
  const [selectedDeposits, setSelectedDeposits] = useState([]);
  const [showDepositsRequired, setShowDepositsRequired] = useState(false);
  const [formState, setFormState] = useState({});
  const [certificate, setCertificate] = useState(null);
  const { placeUri, reservationId } = match.params;

  useEffect(() => {
    getPlace(placeUri);
    getReservation(placeUri, reservationId);
    getDeposits(placeUri);
  }, [placeUri, reservationId, getPlace, getReservation, getDeposits]);

  const isAuthenticated = isDefined(user);

  const addDeposit = useCallback(
    (deposit) => {
      setSelectedDeposits((prevSelectedDeposits) => [
        ...prevSelectedDeposits,
        {
          deposit,
          types: deposit.types,
          depositId: deposit.id,
          type: deposit.types[0],
          depositTypeId: deposit.types[0].id,
          count: 1
        }
      ]);
    },
    [setSelectedDeposits]
  );

  const submitForm = (model) => {
    if (place.depositsActive && deposits.length > 0 && place.onlyDepositReservations && !selectedDeposits.length && !place.showSpots) {
      setShowDepositsRequired(true);
      return;
    }

    if (selectedDeposits.length > 0) {
      model.depositOrder = selectedDeposits.map(item => (
        { count: item.count, depositId: item.depositId, depositTypeId: item.depositTypeId }
      ));
      model.depositUpdated = true;
    }

    if (certificate) {
      model.certificateId = certificate.id;
    }

    if (isAuthenticated) {
      if (isDefined(user.phone)) {
        confirmReservation(placeUri, reservation, model);
      } else {
        saveRegistrationData(model);
        openAskPhoneModal();
      }
    } else {
      confirmReservation(placeUri, reservation, model);
    }
  };

  useEffect(() => {
    if (selectedDeposits.length) {
      setShowDepositsRequired(false);
    }
  }, [selectedDeposits.length]);

  useEffect(()=>{
    if(place?.onlyDepositReservations && deposits?.length === 1){
      !selectedDeposits.length && addDeposit(deposits[0])
    }
  },[place, deposits, selectedDeposits, addDeposit])

  const goToPlacePage = useCallback(() => {
    closeModal();
    history.push(`${baseUrlPath()}/places/${placeUri}`);
  }, [closeModal, placeUri, history]);

  if (loading) {
    return <Loading />;
  }

  if (!reservation) {
    return <Entity404 entity={t('reservation')} />;
  }

  if (reservation.status !== STATUS_DRAFT) {
    return <ReservationNotDraft />;
  }


  const removeDeposit = (id) => {
    setSelectedDeposits(selectedDeposits.filter(d => d.depositId !== id));
  };

  const notSelectedDeposits = deposits.filter(d => !selectedDeposits.find(sd => d.id === sd.depositId));

  const depositQuantityChanged = (deposit, newValue) => {
    if (newValue === 0) {
      removeDeposit(deposit.depositId);
    } else {
      deposit.count = newValue;
      setSelectedDeposits([...selectedDeposits]);
    }
  };

  const newTypeSelected = (deposit, type) => {
    deposit.depositTypeId = type.id;
    deposit.type = type;
    setSelectedDeposits([...selectedDeposits]);
  };

  const searchCertificate = async () => {
    const response = await findCertificate(placeUri, formState.certificateSearch);

    if (response?.data?.id) {
      setCertificate(response.data);
    } else {
      toastr.error(t('certificateNotFound'));
    }
  }

  return (
    <div>
      <div className='finish-reservation'>
        <div className='finish-reservation__reservation-info reservation-info'>
          <div className='reservation-info__back'>
            <TextLink
              className='finish-reservation__back'
              type='text'
              to={`${baseUrlPath()}/places/${placeUri}`}
              size='medium'
            >
              &lt; {t('back')}
            </TextLink>
          </div>
          <div className='reservation-info__almost'>{t('almost')}</div>
          <div className='reservation-info__details'>
            {place.avatarUrl && (
              <div className='reservation-info__photo'>
                <Image src={place.avatarUrl} alt={place.name} cover />
              </div>
            )}
            <div>
              <TextLink
                to={`${baseUrlPath()}/places/${placeUri}`}
                className='reservation-info__resto-name'
              >
                {place.name}
              </TextLink>
              <ReservationDetails reservation={reservation} place={place} />
            </div>
          </div>

          {reservation.spots?.length > 0 && (
            <div className='selected-tables'>
              {reservation.hall && <div>{t('selectedHall')}: {reservation.hall.name}</div>}
              <div>{t('selectedSpots')}: {reservation.spots.map(s => s.label).join(', ')}</div>
              {reservation.bill?.data?.order?.length > 0 && <div style={{ fontWeight: 'bold' }}>{t('deposits')}:</div>}
              {(reservation.bill?.data?.order || []).map(deposit => (
                <>
                  <div className='selected-tables__row' key={deposit.id}>
                    <div>
                      {getDepositName(deposits, deposit.depositId)}
                      {deposits.find(d => d.id === deposit.depositId)?.types?.length > 1 && (
                        <span> | {getDepositTypeName(deposits, deposit.depositTypeId)}</span>
                      )}
                    </div>
                    <div className='selected-tables__price'>{deposit.price} {t('uah')}</div>
                  </div>
                  <div key={deposit.id} className='selected-tables__divider' />
                </>
              ))}
              {reservation.bill?.data?.order?.length > 0 && (
                <div className='selected-tables__row'>
                  <div>{t('total')}</div>
                  <div className='selected-tables__price'>{reservation.bill.amount} {t('uah')}</div>
                </div>
              )}
            </div>
          )}

          <div className='reservation-info__timer timer'>
          <SvgIcon className='timer__icon' type='time' fill={Colors.TEXT_BLACK} />
            <span>{t('timer.prefix1')}</span>&nbsp;
            <span>{t('timer.prefix2')}</span>&nbsp;
            <Timer className='timer__time' millis={reservation.end} onFinish={goToPlacePage} />&nbsp;
            <span>{t('timer.suffix')}</span>
          </div>

          {reservation?.busyHours?.message && (
            <div className='reservation-info__busy-hours reservation-info__row'>
              {reservation?.busyHours?.message[user?.lang || DEFAULT_LANG]}
            </div>
          )}

          <Form onValidSubmit={submitForm} onChange={setFormState}>
            <div className='reservation-info__form form'>
              {
                !isAuthenticated && (
                  <Fragment>
                    { /*
                    <div className='form__row'>
                    <div className='form__column'>
                      <SocialLoginButton provider={PROVIDER_FACEBOOK} fullWidth />
                    </div>
                    <div className='form__column'>
                      <SocialLoginButton provider={PROVIDER_GOOGLE} fullWidth />
                    </div>
                    </div>

                    <div className='form__row'>
                      <div className='form__or'>{t('or')}</div>
                    </div>
                    */ }

                      <div className='form__row'>
                      <div className='form__column'>
                        <TextInput
                          name='firstName'
                          placeholder={`${t('name')}*`}
                          fullWidth
                          fontSize={16}
                          validations={{
                            minLength: 2,
                          }}
                          validationErrors={{
                            minLength: errorsT(`minLength.${getPluralsFactor(2)}`, { symbols: 2 }),
                            isDefaultRequiredValue: errorsT('required')
                          }}
                          required
                        />
                      </div>
                      <div className='form__column'>
                        <TextInput
                          name='lastName'
                          placeholder={t('surname')}
                          fullWidth
                          fontSize={16}
                          validations={{
                            minLength: 2,
                          }}
                          validationErrors={{
                            minLength: errorsT(`minLength.${getPluralsFactor(2)}`, { symbols: 2 }),
                          }}
                        />
                      </div>
                    </div>

                    <div className='form__row'>
                      <div className='form__column'>
                        <PhoneInput
                          name='phone'
                          fullWidth
                          validations={{
                            matchRegexp: phoneRegexp
                          }}
                          validationErrors={{
                            matchRegexp: errorsT('phone'),
                            isDefaultRequiredValue: errorsT('required')
                          }}
                          required
                        />
                      </div>
                    </div>
                  </Fragment>
                )
              }

              {place.showHalls && !place.showSpots && (
                <div className='form__row form__row--block'>
                  <div className='select-hall'>{t('selectHall')}</div>
                  <div className='select-hall__items'>
                    <InfoTagsFormsy name='hallId'>
                      {
                        (setValue, value) => (
                          <Fragment>
                            {(place.halls || []).map(h => (
                              <InfoTag
                                key={h.id}
                                name='hallId'
                                value={h.id}
                                checked={value === h.id}
                                clickable
                                onClick={() => setValue(h.id)}
                                title={h.name}
                              />
                            ))}
                          </Fragment>
                        )}
                    </InfoTagsFormsy>
                  </div>
                </div>
              )}

              {place.depositsActive && deposits.length > 0 && !place.showSpots && (
                <div className='form__row form__row--block'>
                  <div className='select-hall'>{t('depositOptions')}</div>
                  <div className='select-hall__items'>
                    {
                      notSelectedDeposits.map(d => (
                        <InfoTag
                          key={d.id}
                          name='hallId'
                          clickable
                          onClick={() => addDeposit(d)}
                          title={`+ ${d.label}`}
                        />
                      ))
                    }
                  </div>
                </div>
              )}

              {selectedDeposits.length > 0 && (
                <div className='selected-deposits'>
                  {selectedDeposits.map(d => (
                    <div key={d.depositId} className='selected-deposit'>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: '16px'
                        }}
                      >
                        <div style={{ fontSize: 16, fontWeight: 'bold' }}>{d.deposit.label}</div>
                        <div className='selected-deposit__price'>{getDepositTypePrice(reservation, d, d.type)} {t('uah')}</div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '8px' }}>
                          {d.deposit.types.length > 1 && d.types.map(type => (
                            <Button
                              key={type.id}
                              onClick={() => newTypeSelected(d, type)}
                              type={d.depositTypeId === type.id ? 'default' : 'flat'}
                            >
                              {type.label}
                            </Button>
                          ))}
                        </div>
                       { !d.deposit.disableQuantity &&
                        <SelectQuantity
                          selected={d.count}
                          setSelected={(newVal) => depositQuantityChanged(d, newVal)}
                          disableRemove={d.count === 1 && place?.onlyDepositReservations && deposits?.length === 1}
                        /> }
                      </div>
                    </div>
                  ))}
                  <div className='deposit-total'>
                    <span>{t('total')}:</span>
                    <span>{getTotalDepositPrice(reservation, selectedDeposits)} {t('uah')}</span>
                  </div>
                </div>
              )}
              {place.onlyDepositReservations && showDepositsRequired && (
                <div className='deposits-required-error'>{t('depositsRequired')}</div>
              )}

              {place?.showCertificates && !certificate && (
                <div className='form__row form__row--block'>
                  <div className='select-hall'>{t('certificate')}</div>
                  <TextInput
                    name='certificateSearch'
                    className='certificate__search'
                    placeholder={`${t('certificateId')}`}
                    fullWidth
                    fontSize={16}
                  >
                    <Button
                      type='flat'
                      className='certificate__search-button'
                      onClick={searchCertificate}
                      disabled={!formState.certificateSearch}
                    >
                      {t('search')}
                    </Button>
                  </TextInput>
                </div>
              )}
              {place?.showCertificates && certificate && (
                <div className='certificate__card'>
                  <div className='certificate__icon-wrapper'>
                    <SvgIcon
                      type={getCertificateIcon(certificate.certificate)}
                      fill='white'
                      className='certificate__icon'
                    />
                  </div>
                  <div className='certificate__center'>
                    <div className='certificate__name'>{certificate.certificate?.name}</div>
                    <div className='certificate__id'>ID: {certificate.data.uuid}</div>
                  </div>
                  <SvgIcon
                    type='clear'
                    iconProps={{ size: 18 }}
                    onClick={() => setCertificate(null)}
                    className='certificate__icon certificate__icon--clickable'
                  />
                </div>
              )}

              <div className='form__row'>
                <TextArea
                  name='comment'
                  placeholder={t('comment')}
                  fullWidth
                  fontSize={16}
                />
              </div>
            </div>

            {/*
          <div className='reservation-info__row'>
            <Checkbox>{t('subscribe')}</Checkbox>
          </div>

          <div className='reservation-info__row'>
            <Checkbox>{t('notifications')}</Checkbox>
          </div>
          */}

            <div className='reservation-info__row'>
              <Button behavior='submit' fontSize={16} className='finish-reservation__submit'>{t('submit')}</Button>
            </div>
          </Form>

          <div className='reservation-info__row'>
            <ConfirmLegal buttonName={t('buttonName')} addRegistrationParagraph={!isAuthenticated} />
          </div>
        </div>

        <PlaceContacts className='finish-reservation__contacts' place={place} />
      </div>
    </div>
  );
}

FinishReservation.propTypes = {
  openAskPhoneModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getReservation: PropTypes.func.isRequired,
  getPlace: PropTypes.func.isRequired,
  getDeposits: PropTypes.func.isRequired,
  findCertificate: PropTypes.func.isRequired,
  deposits: PropTypes.array.isRequired,
  confirmReservation: PropTypes.func.isRequired,
  saveRegistrationData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object,
  reservation: PropTypes.object,
  place: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
};

FinishReservation.defaultProps = {
  user: undefined,
  reservation: undefined,
  place: undefined,
};

export default FinishReservation;
